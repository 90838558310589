<template>
  <div class="modal tls" role="dialog" aria-labelledby="lis-search-opdracht">
    <div class="modal__close" @click="$emit('on-close')">
      <span class="material-icons md-36">close</span>
    </div>
    <h2 class="modal__title" id="lis-search-opdracht">LIS opdracht selecteren</h2>
    <div class="tls__filters">
      <input
        type="date"
        class="input"
        v-model="search.date"
        @change="getResults"
      />
      <select class="select" v-model="search.location" @change="getResults">
        <option value="">Alle locaties</option>
        <option value="A">Antwerpen (A)</option>
        <option value="G">Gent (G)</option>
        <option value="V">Vlissingen (V)</option>
        <option value="ZZ">Zeebrugge (ZZ)</option>
      </select>
      <input
        type="text"
        class="input"
        placeholder="Naam loods"
        v-model="search.name"
      />
      <input
        type="text"
        class="input"
        placeholder="Adres"
        v-model="search.address"
      />
      <input
        type="text"
        class="input"
        placeholder="Schipnaam"
        v-model="search.shipname"
      />
      <button class="button" :disabled="!search.date" @click="getResults">
        Zoeken
      </button>
      <button class="button button--cancel" @click="resetFilter">Reset</button>
    </div>
    <div
      class="data__table tls__content"
      ref="lis-data"
      v-if="results && results.length"
    >
      <table>
        <thead>
          <tr>
            <th
              v-for="item in Object.keys(results[0])"
              :key="`lis-head--${item}`"
            >
              {{ item }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in results"
            :class="{ selected: item.id === selectedItem?.id }"
            :key="`lis-item--${item.id}`"
            @click="selectedItem = { ...item }"
          >
            <td
              v-for="key in Object.keys(item)"
              :key="`lis-item--${item.id}--${key}`"
            >
              {{ item[key] }}
            </td>
          </tr>
        </tbody>
      </table>
      <transition name="fade">
        <Loader v-if="isLoading" />
      </transition>
    </div>
    <div class="modal__controls">
      <button
        class="button button--accept"
        :disabled="!selectedItem"
        @click="confirmItem"
      >
        Bevestig
      </button>
    </div>
  </div>
  <div class="modal__backdrop" @click="$emit('on-close')"></div>
</template>

<script>
export default {
  name: "TicketLisSearch",

  data() {
    return {
      today: "",
      isLoading: false,
      results: [],
      search: {
        date: "",
        location: "",
        name: "",
        address: "",
        shipname: "",
      },
      selectedItem: null,
    };
  },

  async created() {
    this.setInitDate();
    await this.getResults();
  },

  methods: {
    setInitDate() {
      const today = new Date();
      const tFormat = `${today.getFullYear()}-${today.toLocaleString("nl-BE", {
        month: "2-digit",
      })}-${today.toLocaleString("nl-BE", { day: "2-digit" })}`;
      this.search.date = tFormat;
      this.today = tFormat;
    },

    async getResults() {
      this.selectedItem = null;
      if (!this.search.date) {
        alert("Gelieve een datum te selecteren.");
        return;
      }
      const container = this.$refs["lis-data"];
      if (container) {
        container.scrollTop = 0;
      }

      this.isLoading = true;
      try {
        const payload = { ...this.search };
        payload.date = payload.date.replaceAll("-", "/");
        this.results = await this.$store.dispatch("ticket/lisSearch", payload);
      } catch (e) {
        alert(e);
      }
      this.isLoading = false;
    },

    confirmItem() {
      this.$emit("on-select", { ...this.selectedItem });
    },

    async resetFilter() {
      this.search = {
        date: this.today,
        name: "",
        address: "",
        shipname: "",
      };
      await this.getResults();
    },
  },
};
</script>
