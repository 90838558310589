<template>
  <div class="login">
    <div class="login__wrapper">
      <div class="login__logo">
      <img  src="../assets/logo.png" alt="HES Logo">
      </div>
      <div class="login__content">
        <h1>Log in</h1>
        <form action="" class="form login__form" @submit.prevent="logIn">
          <div class="form__column">
            <label for="username">Gebruiker</label>
            <input type="email" class="input" v-model="user.username">
            <label for="password">Wachtwoord</label>
            <input type="password" class="input" v-model="user.password">
            <div class="form__wide form__wide--right">
              <button class="button" @click.prevent="logIn" :disabled="isLoading">Log in</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      user: {
        username: '',
        password: ''
      },
      isLoading: false
    }
  },

  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated && this.$store.getters['user/userData'];
    }
  },
  watch: {
    isAuthenticated(curValue, oldValue) {
      if (curValue && curValue !== oldValue) {
        if(this.$route.query.nextUrl){
          this.$router.replace(this.$route.query.nextUrl);
          return;
        }
        this.$router.replace('/');
      }
    }
  },

  methods: {
    async logIn() {
      if (!this.user.username || !this.user.password) {
        return;
      }
      this.isLoading = true;
      try {
        await this.$store.dispatch('login', this.user);
        this.user.password = "";
      } catch (e) {
        alert(e);
      }
      this.isLoading = false;
    }
  }
}
</script>