<template>
  <div class="loader">
    <div class="loader__spinner">
      <span class="material-icons">hourglass_full</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loader"
}
</script>