<template>
  <div role="dialog" class="modal tld" aria-labelledby="lis-opdracht-detail">
      <div class="modal__close" @click="$emit('on-close')">
        <span class="material-icons md-36">close</span>
      </div>
      <h2 id="lis-opdracht-detail" class="modal__title">LIS opdracht detail - {{ lisId }}</h2>
      <div class="modal__content">
        <div class="tld__content">
          <div class="form" v-if="resultData">
            <div class="form__column" v-for="i in colCount" :key="`lis-detail-col--${i}`">
              <template v-for="key in Object.keys(resultData).slice((i - 1) * 8, i * 8)"
                        :key="`lis-detail-col--${i}-${key}`">
                <label>{{ key }}</label>
                <input type="text" class="input" :value="resultData[key]" readonly>
              </template>
            </div>
          </div>
          <transition name="fade">
            <Loader v-if="isLoading"/>
          </transition>
        </div>
      </div>
  </div>
  <div class="modal__backdrop" @click="$emit('on-close')"></div>
</template>

<script>
export default {
  name: "TicketLisDetail",
  props: {
    lisId: {required: true}
  },
  data() {
    return {
      isLoading: false,
      resultData: null,
    }
  },
  async created() {
    await this.getDetail();
  },

  computed: {
    colCount() {
      return this.resultData ? Math.ceil(Object.keys(this.resultData).length / 8) : 0;
    }
  },

  methods: {
    async getDetail() {
      this.isLoading = true;
      try {
        this.resultData = await this.$store.dispatch('ticket/getLisInfo', {id: this.lisId})
      } catch (e) {
        alert(e);
      }
      this.isLoading = false;
    }
  }
}
</script>

<style scoped>

</style>