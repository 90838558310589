export default {
    async fetchPostRequest({url, token, payload, errorMsg}){
        const headers = {}
        headers['Content-type'] = "application/json";
        if(token){
            headers['Authorization'] = `Bearer ${token}`;
        }
        const response = await fetch(url, {
            method: 'POST',
            headers,
            body: payload
        });
        return await this.handleResponseData(response, errorMsg);
    },

    async fetchGetRequest({url, token, errorMsg}){
        const headers = {}
        headers['Content-type'] = "application/json";
        if(token){
            headers['Authorization'] = `Bearer ${token}`;
        }
        const response = await fetch(url,{
            headers
        });
        return await this.handleResponseData(response, errorMsg);
    },

    async handleResponseData(response, errorMsg){
        const responseData = await response.json();
        if (!response.ok || !(responseData.status||responseData.result)) {
            const error = new Error(
                responseData.error_description || errorMsg
            );
            throw error;
        }
        return responseData;
    }
}