let timer;
export default {
    async login(context, payload) {
        return context.dispatch('auth', payload);
    },

    async auth(context, payload) {
        const url =
            `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}${process.env.VUE_APP_ROOT_API_AUTH}`;

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                grant_type: 'password',
                scope: 'listicketsweb',
                code: 'code',
                username: payload.username,
                password: payload.password,
                client_id: process.env.VUE_APP_CLIENT_ID,
                client_secret: process.env.VUE_APP_CLIENT_SECRET
            })
        });
        const responseData = await response.json();

        if (!response.ok || responseData.error) {
            const error = new Error(
                responseData.error_description || 'Failed to authenticate. Check your login data.'
            );
            throw error;
        }

        const expiresIn = +responseData.expires_in * 1000;
        const expirationDate = new Date().getTime() + expiresIn;

        localStorage.setItem('token', responseData.access_token);
        localStorage.setItem('refreshToken', responseData.refresh_token);
        localStorage.setItem('userName', payload.username);
        localStorage.setItem('userId', responseData.user_id);
        localStorage.setItem('tokenExpiration', expirationDate);

        timer = setTimeout(function() {
            context.dispatch('refreshToken');
        }, expiresIn);

        context.commit('setUser', {
            token: responseData.access_token,
            userId: responseData.user_id,
            userName: payload.username
        });

        await context.dispatch('getInitData');

    },

    async refreshToken(context) {
        const userName = localStorage.getItem('userName');
        const userId = localStorage.getItem('userId');
        const refreshtoken = localStorage.getItem('refreshToken');
        if(!refreshtoken || !userName){
            await context.dispatch('autoLogout');
            return;
        }
        let url =
            `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}${process.env.VUE_APP_ROOT_API_AUTH}`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                grant_type: 'refresh_token',
                scope: 'listicketsweb',
                refresh_token: refreshtoken,
                client_id: process.env.VUE_APP_CLIENT_ID,
                client_secret: process.env.VUE_APP_CLIENT_SECRET
            })
        });
        const responseData = await response.json();

        if (!response.ok || responseData.error) {
            const error = new Error(
                responseData.message || 'Failed to re-authenticate.'
            );
            await context.dispatch('autoLogout');
            throw error;
        }

        const expiresIn = +responseData.expires_in * 1000;
        const expirationDate = new Date().getTime() + expiresIn;

        localStorage.setItem('token', responseData.access_token);
        localStorage.setItem('refreshToken', responseData.refresh_token);
        localStorage.setItem('tokenExpiration', expirationDate);

        timer = setTimeout(function() {
            context.dispatch('refreshToken');
        }, expiresIn);

        context.commit('setUser', {
            token: responseData.access_token,
            userId: userId,
            userName: userName
        });
        await context.dispatch('getInitData');
    },
    async tryLogin(context) {
        const token = localStorage.getItem('token');
        const refreshToken = localStorage.getItem('refreshToken');
        const userName= localStorage.getItem('userName');
        const userId = localStorage.getItem('userId');
        const tokenExpiration = localStorage.getItem('tokenExpiration');

        const expiresIn = +tokenExpiration - new Date().getTime();

        if(!refreshToken){
            return;
        }

        if ((!expiresIn || expiresIn < 0) && refreshToken) {
            await context.dispatch('refreshToken');
            return;
        }

        timer = setTimeout(async function() {
            await context.dispatch('refreshToken');
        }, expiresIn);

        if (token && userName) {
            context.commit('setUser', {
                token: token,
                userId: userId,
                userName: userName
            });
            await context.dispatch('getInitData');
        }
    },
    async getInitData(context){
        try {
            await context.dispatch('user/getUserData');
            if(!context.getters['user/userData'].active){
                console.log('Logging out inactive user...');
                await context.dispatch('autoLogout');
                return;
            }
            await context.dispatch('user/getUsers');
        }catch(e){
            await context.dispatch('autoLogout');
            throw e;
        }
    },
    logout(context) {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('userName');
        localStorage.removeItem('userId');
        localStorage.removeItem('tokenExpiration');

        clearTimeout(timer);

        context.commit('setUser', {
            token: null,
            userName: null
        });
        context.dispatch('user/clearUserData');
    },
    autoLogout(context) {
        context.dispatch('logout');
        context.commit('setAutoLogout');
    }
}