import apihelper from "@/helpers/apihelper";
export default{
    async getTickets(context, payload) {
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/tickets/listTickets`;
        const responseData = await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to fetch tickets.'
        });
        context.commit('setTickets', responseData.data);
        context.commit('setPages', Math.ceil(responseData.metadata.count / responseData.metadata.PageSize));
    },

    clearTickets(context){
        context.commit('setTickets', []);
        context.commit('setPages', 0);
    },

    async printTickets(context, payload) {
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/tickets/printTickets`;
        const responseData = await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to generate pdf.'
        });
        return responseData.data;
    },

    async getTicket(context, {id}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/tickets/${id}`;
        const responseData = await apihelper.fetchGetRequest({
            url,
            token,
            errorMsg: 'Failed to fetch ticket data.'
        });
        return responseData.data;
    },
    async addTicket(context, payload){
        const token = context.rootGetters.token;
        const user = context.rootGetters['user/userData'];
        payload.user = {
            id:user.id,
            name: `${user.first_name} ${user.last_name}`
        }
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/tickets/addTicket`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to add ticket.'
        });
    },
    async editTicket(context, payload){
        const token = context.rootGetters.token;
        const user = context.rootGetters['user/userData'];
        payload.user = {
            id:user.id,
            name: `${user.first_name} ${user.last_name}`
        }
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/tickets/editTicket`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to edit ticket.'
        });
    },

    async getReactions(context, {id}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/tickets/listreactions?ticket_id=${id}`;
        const responseData = await apihelper.fetchGetRequest({url,token,errorMsg:'Failed to fetch reactions'});
        return responseData.data;
    },
    async addReaction(context, payload){
        const token = context.rootGetters.token;
        const user = context.rootGetters['user/userData'];
        payload.user = {
            id:user.id,
            name: `${user.first_name} ${user.last_name}`
        }
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/tickets/addReaction`;
        const responseData = await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to add reaction.'
        });
        return responseData.data;
    },
    async getAttachments(context, {ticket_id,reaction_id}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/tickets/listattachments?ticket_id=${ticket_id}${reaction_id?`&reaction_id=${reaction_id}}`:''}`;
        const responseData = await apihelper.fetchGetRequest({url,token,errorMsg:'Failed to fetch attachments'});
        return responseData.data;
    },

    async addAttachment(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/tickets/addAttachment`;

        const user = context.rootGetters['user/userData'];
        const formData = new FormData();
        formData.append('user_id',user.id);
        Object.keys(payload).forEach(key => {
            formData.append(key,payload[key]);
        });
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: formData
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to add attachment.'
            );
            throw error;
        }
    },
    async editAttachment(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/tickets/editAttachment`;

        const user = context.rootGetters['user/userData'];
        const formData = new FormData();
        formData.append('user_id',user.id);
        Object.keys(payload).forEach(key => {
            formData.append(key,payload[key]);
        });
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: formData
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to edit attachment.'
            );
            throw error;
        }
    },
    async delAttachment(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/tickets/delAttachment`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to delete attachment.'
        });
    },

    async subscribeTicket(context, {ticket_id,on}){
        const user_id = context.rootGetters.userId;
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/tickets/subscribe?ticket_id=${ticket_id}&user_id=${user_id}&on=${on}`;
        await apihelper.fetchGetRequest({
            url,
            token,
            errorMsg: 'Failed to toggle subscribe to ticket.'
        });
    },
    async subscribeStatus(context, {ticket_id}){
        const user_id = context.rootGetters.userId;
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/tickets/subscribestatus?ticket_id=${ticket_id}&user_id=${user_id}`;
        const responseData = await apihelper.fetchGetRequest({
            url,
            token,
            errorMsg: 'Failed to get subscribe status for ticket.'
        });
        return responseData.data?.subscribed;
    },

    async lisSearch(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/Lis/search`;
        const responseData = await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to fetch results for lis search.'
        });
        return responseData.data;
    },
    async getLisInfo(context, {id}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/Lis/${id}`;
        const responseData = await apihelper.fetchGetRequest({
            url,
            token,
            errorMsg: 'Failed to fetch LISopdracht data by id.'
        });
        return responseData.data;
    }
}