<template>
  <div class="page">
    <h1>
      Tickets
      <button class="icon-button" @click="refetchTickets">
        <span class="material-icons md-48">sync</span>
      </button>
      <button class="icon-button" :disabled="printing" @click="openPrint">
        <span class="material-icons md-48">print</span>
      </button>
    </h1>
    <div class="page__content">
      <div class="tickets">
        <div>
          <div class="tickets__controls">
            <button
              class="button button--accept"
              @click="addTicket"
              ref="add_ticket"
            >
              Nieuw ticket +
            </button>
            <label>Titel</label>
            <input
              type="text"
              placeholder="Titel"
              class="input"
              v-model="search.title"
              @dblclick.prevent="search.title = ''"
            />
            <label>LIS ID</label>
            <input
              type="text"
              placeholder="LIS ID"
              class="input"
              v-model.number="search.lisopdracht_id"
              @dblclick.prevent="search.lisopdracht_id = ''"
            />
            <label>Locatie</label>
            <div class="tickets__controls__checks">
              <label for="locA">Antwerpen (A)</label>
              <input
                name="locA"
                id="locA"
                type="checkbox"
                v-model="search.location"
                value="A"
              />
              <label for="locG">Gent (G)</label>
              <input
                name="locG"
                id="locG"
                type="checkbox"
                v-model="search.location"
                value="G"
              />
              <label for="locV">Vlissingen (V)</label>
              <input
                name="locV"
                id="locV"
                type="checkbox"
                v-model="search.location"
                value="V"
              />
              <label for="locZZ">Zeebrugge (ZZ)</label>
              <input
                name="locZZ"
                id="locZZ"
                type="checkbox"
                v-model="search.location"
                value="ZZ"
              />
            </div>
            <label>Categorie</label>
            <select class="select" v-model="search.category">
              <option value="">Toon alles</option>
              <option value="chauffeur">Chauffeur</option>
              <option value="wagenpark">Wagenpark</option>
              <option value="loods">Loods</option>
              <option value="no show">No show</option>
              <option value="te laat">Te laat</option>
              <option value="niet doorgegeven">niet doorgegeven</option>
              <option value="IT">IT</option>
            </select>
            <label>Status</label>
            <select class="select" v-model="search.status">
              <option value="">Toon alles</option>
              <option value="open">Open</option>
              <option value="in progress">In progress</option>
              <option value="closed">Closed</option>
            </select>
            <label>Prioriteit</label>
            <div class="tickets__controls__checks">
              <label for="norm">normaal</label>
              <input
                id="norm"
                type="checkbox"
                v-model="search.priority"
                :value="0"
              />
              <label for="high">hoog</label>
              <input
                id="hight"
                type="checkbox"
                v-model="search.priority"
                :value="1"
              />
              <label for="highest">hoogste</label>
              <input
                id="highest"
                type="checkbox"
                v-model="search.priority"
                :value="2"
              />
            </div>
            <div class="tickets__controls__pagination" v-if="pages">
              <span>Pagina</span>
              <button
                class="icon-button"
                :disabled="page === 1"
                @click="page--"
              >
                <span class="material-icons md-36">chevron_left</span>
              </button>
              <span>{{ `${page} / ${pages}` }}</span>
              <button
                class="icon-button"
                :disabled="page === pages"
                @click="page++"
              >
                <span class="material-icons md-36">chevron_right</span>
              </button>
            </div>
            <button class="button button--cancel" @click="resetFilter">
              Reset Filters
            </button>
          </div>
        </div>
        <div>
          <div class="tickets__grid" id="data-grid">
            <template v-if="tickets && tickets.length">
              <div
                :ref="`ticket--${item.id}`"
                class="ticket"
                v-for="item in tickets"
                :key="`ticket--${item.id}`"
                :class="{ 'ticket--closed': item.status === 'closed' }"
                @click="openTicket(item.id)"
              >
                <div class="ticket__title">
                  <h2>
                    {{ item.title }}
                  </h2>
                  <div>
                    <span
                      class="material-icons"
                      :title="`prioriteit: ${item.priority}`"
                      :class="{
                        error: item.priority == 2,
                        warn: item.priority == 1,
                        success: item.priority == 0,
                      }"
                      >warning_amber</span
                    >
                  </div>
                </div>
                <div class="ticket__subtitle">
                  <span
                    >{{ item.id }} - {{ item.category }} -
                    {{ item.lisopdracht_id }}</span
                  ><span :title="`status: ${item.status}`">{{
                    item.status
                  }}</span>
                </div>
                <div class="ticket__subtitle">
                  <span>{{
                    `${item.A ? "A" : ""}${item.G ? "G" : ""}${
                      item.V ? "V" : ""
                    }${item.ZZ ? "ZZ" : ""}`
                  }}</span>
                </div>
                <div class="ticket__content">
                  <p class="ticket__content__text">{{ item.message }}</p>
                </div>
                <p class="ticket__footer">
                  <span>{{ formatUserName(item.user_id) }}</span>
                  <span>{{ formatDate(item.creationdate) }}</span>
                </p>
              </div>
            </template>
            <p class="no-results" v-else>Geen resultaten.</p>
          </div>
          <Loader v-if="isLoading" />
        </div>
      </div>
    </div>
    <teleport to="#modals">
      <template v-if="newTicket">
        <focus-trap :active="newTicket">
          <div
            role="dialog"
            aria-labelledby="new-title-dialog"
            class="modal tickets__new"
          >
            <div class="modal__close" @click="closeTicket">
              <span class="material-icons md-36">close</span>
            </div>
            <h2 class="modal__title" id="new-title-dialog">Ticket aanmaken</h2>
            <div class="modal__content">
              <form action="" @submit.prevent="" class="form">
                <div class="form__column">
                  <label>Titel</label>
                  <input
                    type="text"
                    class="input"
                    v-model="newTicket.title"
                    ref="new_ticket_title"
                  />
                  <label>Categorie</label>
                  <select class="select" v-model="newTicket.category">
                    <option value="" disabled>-- Kies een categorie --</option>
                    <option value="chauffeur">Chauffeur</option>
                    <option value="wagenpark">Wagenpark</option>
                    <option value="loods">Loods</option>
                    <option value="no show">No show</option>
                    <option value="te laat">Te laat</option>
                    <option value="niet doorgegeven">niet doorgegeven</option>
                    <option value="IT">IT</option>
                  </select>
                  <label>Prioriteit</label>
                  <select class="select" v-model="newTicket.priority">
                    <option :value="0">Normaal</option>
                    <option :value="1">Hoog</option>
                    <option :value="2">Hoogste</option>
                  </select>
                  <label>Locatie</label>
                  <div class="tickets__controls__checks">
                    <label for="nlocA">Antwerpen (A)</label>
                    <input
                      name="nlocA"
                      id="nlocA"
                      type="checkbox"
                      v-model="newTicket.A"
                      :true-value="1"
                      :false-value="0"
                    />
                    <label for="nlocG">Gent (G)</label>
                    <input
                      name="nlocG"
                      id="nlocG"
                      type="checkbox"
                      v-model="newTicket.G"
                      :true-value="1"
                      :false-value="0"
                    />
                    <label for="nlocV">Vlissingen (V)</label>
                    <input
                      name="nlocV"
                      id="nlocV"
                      type="checkbox"
                      v-model="newTicket.V"
                      :true-value="1"
                      :false-value="0"
                    />
                    <label for="nlocZZ">Zeebrugge (ZZ)</label>
                    <input
                      name="nlocZZ"
                      id="nlocZZ"
                      type="checkbox"
                      v-model="newTicket.ZZ"
                      :true-value="1"
                      :false-value="0"
                    />
                  </div>
                  <label>LIS ID</label>
                  <div class="lis__search">
                    <input
                      type="number"
                      class="input"
                      v-model.number.trim="newTicket.lisopdracht_id"
                    />
                    <button class="button" @click="searchLisActive = true">
                      Zoek
                    </button>
                  </div>
                  <label>Omschrijving</label>
                  <textarea
                    class="textarea"
                    v-model="newTicket.message"
                  ></textarea>
                </div>
              </form>
            </div>
            <div class="modal__controls">
              <button
                class="button button--accept"
                :disabled="isLoading"
                @click.prevent="submitTicket"
              >
                Opslaan
              </button>
            </div>
          </div>
        </focus-trap>
        <div class="modal__backdrop" @click="closeTicket"></div>
      </template>

      <template v-if="isPrintVisible">
        <focus-trap :active="isPrintVisible">
          <div
            role="dialog"
            aria-labelledby="new-title-dialog"
            class="modal tickets__new"
          >
            <div class="modal__close" @click="isPrintVisible = false">
              <span class="material-icons md-36">close</span>
            </div>
            <h2 class="modal__title" id="new-title-dialog">Tickets printen</h2>
            <div class="modal__content">
              <form action="" @submit.prevent="" class="form">
                <div class="form__column">
                  <label>Van</label>
                  <input
                    type="date"
                    class="input"
                    v-model="yesterday"
                  />
                  <label>Tot</label>
                  <input
                    type="date"
                    class="input"
                    v-model="today"
                  />
                </div>
              </form>
            </div>
            <div class="modal__controls">
              <button
                class="button button--accept"
                :disabled="printing"
                @click.prevent="printTickets"
              >
                Printen
              </button>
            </div>
          </div>
        </focus-trap>
        <div class="modal__backdrop" @click="isPrintVisible=false"></div>
      </template>  

      <TicketDetail
        v-if="activeTicket"
        :ticket="activeTicket"
        @on-close="closeTicket"
        @on-save="refetchTickets"
      />
      <TicketLisSearch
        v-if="searchLisActive"
        @on-select="onLisSelectionChanged"
        @on-close="searchLisActive = false"
      />
    </teleport>
  </div>
</template>

<script>
import TicketDetail from "../components/Tickets/TicketDetail";
import TicketLisSearch from "../components/Tickets/TicketLisSearch";
import format from "../mixins/format";

export default {
  name: "TicketOverview",
  components: {
    TicketDetail,
    TicketLisSearch,
  },
  mixins: [format],
  data() {
    return {
      today:"",
      yesterday:"",
      isPrintVisible: false,
      isLoading: false,
      printing: false,
      activeTicket: null,
      search: {
        status: "",
        location: [],
        lisopdracht_id: "",
        title: "",
        category: "",
        priority: [0, 1, 2],
      },
      page: 1,
      pageSize: 50,
      orderBy: "id desc",
      searchTimeout: null,
      deleteItem: null,
      newTicket: null,

      searchLisActive: false,

      lastFocus: null,
    };
  },
  async created() {
    if (this.ticketId) {
      await this.openTicket(this.ticketId);
    }
    if (this.userData) {
      this.search.location = this.userLocations;
      this.getTickets();
    }
  },

  beforeUnmount() {
    clearTimeout(this.searchTimeout);
    this.$store.dispatch("ticket/clearTickets");
  },

  computed: {
    userData() {
      return this.$store.getters["user/userData"];
    },
    userLocations() {
      return this.$store.getters["user/userLocations"];
    },
    tickets() {
      return this.$store.getters["ticket/tickets"];
    },
    pages() {
      return this.$store.getters["ticket/pages"];
    },
    isReadonly() {
      return this.$store.getters["user/userData"]?.readonly;
    },
    ticketId() {
      return this.$route.params.id;
    },
  },

  watch: {
    page() {
      this.getTickets();
    },

    userData() {
      if (this.userData) {
        this.search.location = this.userLocations;
        this.getTickets();
      }
    },

    search: {
      deep: true,
      handler() {
        this.refetchTickets();
      },
    },

    orderBy() {
      this.refetchTickets();
    },
  },

  methods: {
    resetDates() {
      const date = new Date();
      const today = `${date.toLocaleString("default", {
        year: "numeric",
      })}-${date.toLocaleString("default", {
        month: "2-digit",
      })}-${date.toLocaleString("default", { day: "2-digit" })}`;
      const yesterday = new Date();
      yesterday.setDate(date.getDate()-1);
      const endDate = `${yesterday.toLocaleString("default", {
        year: "numeric",
      })}-${yesterday.toLocaleString("default", {
        month: "2-digit",
      })}-${yesterday.toLocaleString("default", { day: "2-digit" })}`;
      this.today = today;
      this.yesterday = endDate;
    },

    openPrint(){
      this.resetDates();
      this.isPrintVisible = true;
    },
    async getTickets() {
      this.isLoading = true;
      const container = document.getElementById("data-grid");
      if (container) {
        container.scrollTop = 0;
      }
      try {
        await this.$store.dispatch("ticket/getTickets", {
          pageSize: this.pageSize,
          page: this.page,
          orderBy: this.orderBy,
          ...this.search,
        });
      } catch (e) {
        alert(e);
      }
      this.isLoading = false;
    },

    async printTickets() {
      this.printing = true;
      try {
        const data = await this.$store.dispatch("ticket/printTickets", {
          ...this.search,
          date:[this.yesterday.replaceAll('-','/'),this.today.replaceAll('-','/')]
        });
        if (data.pdf) {
          window.open(`${data.pdf}?${Date.now()}`);
        }
        this.isPrintVisible = false;
      } catch (e) {
        alert(e);
      }
      this.printing = false;
    },

    async refetchTickets() {
      clearTimeout(this.searchTimeout);
      if (this.search) {
        this.searchTimeout = setTimeout(async () => {
          this.page = 1;
          await this.getTickets();
        }, 200);
      } else {
        this.$store.dispatch("ticket/clearTickets");
      }
    },
    addTicket() {
      this.newTicket = {
        lisopdracht_id: "",
        category: "",
        priority: 0,
        status: "open",
        message: "",
        title: "",
        A: this.userData.A || 0,
        G: this.userData.G || 0,
        V: this.userData.V || 0,
        ZZ: this.userData.ZZ || 0,
      };
      this.lastFocus = this.$refs.add_ticket;
      this.$nextTick(() => {
        this.$refs.new_ticket_title.focus();
      });
    },
    async submitTicket() {
      const nT = this.newTicket;
      if (!nT.message || !nT.title) {
        alert("Gelieve titel en omschrijving in te vullen.");
        return;
      }
      try {
        await this.$store.dispatch("ticket/addTicket", { ...this.newTicket });
        this.closeTicket();
        await this.getTickets();
      } catch (e) {
        alert(e);
      }
      this.isLoading = false;
    },
    resetFilter() {
      this.search = {
        status: "",
        location: ["A", "G", "V", "ZZ"],
        lisopdracht_id: "",
        title: "",
        category: "",
        priority: [0, 1, 2],
      };
    },
    async openTicket(id) {
      this.lastFocus = this.$refs[`ticket--${id}`];
      try {
        this.activeTicket = await this.$store.dispatch("ticket/getTicket", {
          id,
        });
      } catch (e) {
        alert(e);
      }
    },
    closeTicket() {
      this.activeTicket = null;
      this.newTicket = null;
      this.lastFocus.focus();
      this.lastFocus = null;
    },
    onLisSelectionChanged(value) {
      if (this.newTicket) {
        this.newTicket.lisopdracht_id = value?.LisOpdrachtId;
      }
      this.searchLisActive = false;
    },
  },
};
</script>
