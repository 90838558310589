export default {
    computed: {
        users() {
            return this.$store.getters['user/users'];
        }
    },
    methods: {

        formatDate(datestr) {
            const parts = datestr.split(' ');
            const dateparts = parts[0].split('-');
            const timeparts = parts[1].split(':');
            if(parts.length !== 2 || dateparts.length !== 3 || timeparts.length !== 3){
                return 'onbekend tijdstip';
            }
            return `${dateparts[2]}/${dateparts[1]}/${dateparts[0]} - ${timeparts[0]}:${timeparts[1]}`;
        },

        formatUserName(id) {
            const user = this.users.find(u => u.id == id);
            return user ? `${user.first_name} ${user.last_name}` : 'onbekend';
        }
    },
}