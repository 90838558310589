<template>
  <div class="nav">
    <router-link to="/" class="nav__logo"><img src="../assets/logo.png" alt="HES logo"></router-link>
    <div class="nav__menu">
      <div class="nav__link">
        <router-link to="/tickets"><span>Tickets</span></router-link>
      </div>
      <div class="nav__link">
          <template v-if="userData.admin">
            <router-link to="/users"><span>Gebruikers</span></router-link>
          </template>
      </div>
      <div class="nav__link">
        <a href="/doc/manual.pdf" target="_blank"><span class="material-icons md-24">help_outline</span></a>
      </div>
    </div>
    <div class="nav__mobile">
      <span class="material-icons md-36" @click="menuVisible=!menuVisible">{{menuVisible?'close':'menu'}}</span>
      <div class="nav__mobile__menu" v-if="menuVisible">
        <router-link to="/tickets" class="nav__link"><span>Tickets</span></router-link>
        <template v-if="userData.admin">
        <router-link to="/users" class="nav__link"><span>Gebruikers</span></router-link>
        </template>
        <a class="nav__link" href="/doc/manual.pdf" target="_blank"><span>Handleiding</span></a>
      </div>
    </div>

    <div class="nav__user">
      <span>{{ `${userData.first_name} ${userData.last_name}` }}</span>
      <span class="nav__user__logout material-icons md-36" @click="logout">power_settings_new</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Navigation",

  data(){
    return{
      menuVisible:false,
    }
  },

  computed: {
    userData() {
      return this.$store.getters['user/userData'];
    }
  },
  watch:{
    '$route' () {
      this.menuVisible = false;
    }
  },
  methods:{
    logout(){
      this.$store.dispatch('logout');
      this.$router.replace('/login');
    }
  }
}
</script>