import { createStore } from 'vuex';
import AuthModule from './modules/auth';
import UserModule from './modules/user';
import TicketModule from './modules/ticket';

export default createStore({
  modules: {
    auth: AuthModule,
    user: UserModule,
    ticket: TicketModule,
  }
})
