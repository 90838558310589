import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';
import TicketOverview from '../views/TicketOverview.vue';
import Login from "../views/Login";


const routes = [
  {
    path: '/',
    redirect: '/tickets'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {requiresUnauth: true}
  },
  {
    path: '/reset-password',
    name: 'Reset-Password',
    component: () => import('../views/ResetPassword'),
    meta: {requiresUnauth: true}
  },
  {
    path: '/tickets/:id?',
    name: 'TicketDetail',
    component: TicketOverview,
    meta: {requiresAuth: true}
  },
  {
    path: '/users',
    name: 'UserOverview',
    component: () => import('../views/UserOverview'),
    meta: {requiresAuth: true, requiresAdmin: true}
  },
  {
    path:'/:pathMatch(.*)*',
    redirect:'/'
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(function (to, _, next) {
  if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
    next({
      path: '/login',
      query: {
        nextUrl: to.fullPath
      }
    });
  } else if (to.meta.requiresUnauth && store.getters.isAuthenticated) {
    next('/');
  } else {
    if(to.meta.requiresAdmin && !store.getters["user/userData"]?.admin){
      next('/')
    }else{
      next();
    }
  }
});

export default router;
