<template>
  <Navigation v-if="isAuthenticated" />
  <router-view/>
  <div class="update" v-if="updateExists">
    <span>Nieuwe versie beschikbaar</span>
    <button class="button" @click="refreshApp">Herlaad applicatie</button>
  </div>
</template>

<script>
import update from "@/mixins/update";
import Navigation from "@/components/Navigation";
export default {
  mixins: [update],
  components:{
    Navigation
  },
  created() {
    this.tryLogin();
  },
  computed: {
    didAutoLogout() {
      return this.$store.getters.didAutoLogout;
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated && this.$store.getters['user/userData'];
    },
  },

  watch:{
    didAutoLogout(newValue, oldValue){
      if(newValue !== oldValue && newValue){
        this.$router.replace('/login');
      }
    }
  },

  methods: {
    async tryLogin() {
      try {
        await this.$store.dispatch('tryLogin');
      } catch (e) {
        alert(e);
        this.$store.dispatch('logout');
      }
    }
  }
}
</script>

<style lang="scss">
@import 'assets/scss/app';
</style>


