export default {
    userData(state){
        return state.userData;
    },

    userLocations(state){
        const ud = state.userData
        if(!ud){
            return ['A', 'G', 'V', 'ZZ'];
        }
        const locs = [];
        if(ud.A){
            locs.push('A');
        }
        if(ud.G){
            locs.push('G');
        }
        if(ud.V){
            locs.push('V');
        }
        if(ud.ZZ){
            locs.push('ZZ');
        }
        return locs;
    },

    users(state){
        return state.users;
    }
};