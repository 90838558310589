<template>
  <template v-if="!searchLisActive && !activeMission">
    <focus-trap :active="!searchLisActive && !activeMission">
      <div
        class="modal td"
        role="dialog"
        ref="tdRef"
        aria-labelledby="ticket-detail-dialog"
      >
        <div class="modal__close" @click="$emit('on-close')">
          <span class="material-icons md-36">close</span>
        </div>
        <h2 id="ticket-detail-dialog" class="modal__title">
          Ticket {{ ticket.id }} - "{{ ticket.title }}" ({{
            copyTicket.status
          }})
          <button
            :title="
              !!subStatus ? 'Ticket wordt gevolgd' : 'Ticket wordt niet gevolgd'
            "
            class="icon-button md-36"
            @click="toggleSubscribe"
          >
            <span class="material-icons">{{
              !!subStatus ? "visibility" : "visibility_off"
            }}</span>
          </button>
        </h2>
        <div class="td__content">
          <div class="td__content__info">
            <h3>Info</h3>
            <form action="" class="form" @submit.prevent="">
              <div class="form__column">
                <label>Titel</label>
                <input
                  ref="edit_ticket_title"
                  type="text"
                  class="input"
                  v-model="copyTicket.title"
                  :readonly="!isTicketAdmin || copyTicket.status === 'closed'"
                />
                <label>Categorie</label>
                <select
                  class="select"
                  v-model="copyTicket.category"
                  :disabled="!isTicketAdmin || copyTicket.status === 'closed'"
                >
                  <option value="" disabled>-- Kies een categorie --</option>
                  <option value="chauffeur">Chauffeur</option>
                  <option value="wagenpark">Wagenpark</option>
                  <option value="loods">Loods</option>
                  <option value="no show">No show</option>
                  <option value="te laat">Te laat</option>
                  <option value="niet doorgegeven">niet doorgegeven</option>
                  <option value="IT">IT</option>
                </select>
                <label>Prioriteit</label>
                <select
                  class="select"
                  v-model="copyTicket.priority"
                  :disabled="!isTicketAdmin || copyTicket.status === 'closed'"
                >
                  <option :value="0">Normaal</option>
                  <option :value="1">Hoog</option>
                  <option :value="2">Hoogste</option>
                </select>
                <label>Locatie(s)</label>
                <div class="tickets__controls__checks">
                  <label for="elocA">Antwerpen (A)</label>
                  <input
                    :disabled="!isTicketAdmin || copyTicket.status === 'closed'"
                    name="elocA"
                    id="elocA"
                    type="checkbox"
                    v-model="copyTicket.A"
                    :true-value="1"
                    :false-value="0"
                  />
                  <label for="elocG">Gent (G)</label>
                  <input
                    :disabled="!isTicketAdmin || copyTicket.status === 'closed'"
                    name="elocG"
                    id="elocG"
                    type="checkbox"
                    v-model="copyTicket.G"
                    :true-value="1"
                    :false-value="0"
                  />
                  <label for="elocV">Vlissingen (V)</label>
                  <input
                    :disabled="!isTicketAdmin || copyTicket.status === 'closed'"
                    name="elocV"
                    id="elocV"
                    type="checkbox"
                    v-model="copyTicket.V"
                    :true-value="1"
                    :false-value="0"
                  />
                  <label for="elocZZ">Zeebrugge (ZZ)</label>
                  <input
                    :disabled="!isTicketAdmin || copyTicket.status === 'closed'"
                    name="elocZZ"
                    id="elocZZ"
                    type="checkbox"
                    v-model="copyTicket.ZZ"
                    :true-value="1"
                    :false-value="0"
                  />
                </div>
                <label>LIS ID</label>
                <div class="lis__search">
                  <input
                    type="number"
                    class="input"
                    v-model.number.trim="copyTicket.lisopdracht_id"
                  />
                  <button
                    class="button"
                    :disabled="!isTicketAdmin || copyTicket.status === 'closed'"
                    @click="searchLisActive = true"
                  >
                    Zoek
                  </button>
                </div>
                <label>Omschrijving</label>
                <textarea
                  class="textarea"
                  v-model="copyTicket.message"
                  :readonly="!isTicketAdmin || copyTicket.status === 'closed'"
                ></textarea>
                <p class="td__content__info__footer form__wide">
                  <span>{{ formatUserName(ticket.user_id) }}</span>
                  <span>{{ formatDate(ticket.creationdate) }}</span>
                </p>
              </div>
            </form>
            <div class="td__content__controls">
              <button
                class="button button"
                v-if="ticket.lisopdracht_id"
                @click="activeMission = ticket.lisopdracht_id"
              >
                Toon LIS opdracht
              </button>
              <button
                class="button button"
                :disabled="printing"
                @click="printTicket"
              >
                Print TICKET
              </button>
              <button
                class="button button--accept"
                v-if="isTicketAdmin && copyTicket.status !== 'closed'"
                @click.prevent="submitTicket"
              >
                Opslaan
              </button>
              <button
                class="button button--accept"
                v-else-if="(isTicketAdmin || isModerator) && copyTicket.status === 'closed'"
                @click.prevent="updateTicket('open')"
              >
                HEROPEN TICKET
              </button>
              <button
                class="button"
                v-if="copyTicket.status === 'open'"
                @click.prevent="updateTicket('in progress')"
              >
                START TICKET
              </button>
              <button
                class="button button--cancel"
                v-else-if="
                  (isTicketAdmin || isModerator) &&
                  copyTicket.status === 'in progress'
                "
                @click.prevent="updateTicket('closed')"
              >
                CLOSE TICKET
              </button>
            </div>
          </div>
          <div class="td__content__disc">
            <h3>Bijlages</h3>
            <div class="td__content__attachments" v-if="attachments.length">
              <div class="data__table data__table--edit">
                <table>
                  <thead>
                    <tr>
                      <th>Bijlage</th>
                      <th>Datum</th>
                      <th>Auteur</th>
                      <th>Bron</th>
                      <th v-if="copyTicket.status !== 'closed'"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in attachments"
                      :key="`att-item--${item.id}`"
                    >
                      <td>
                        <input
                          type="text"
                          class="input"
                          v-if="
                            activeAttachment && activeAttachment?.id === item.id
                          "
                          v-model="activeAttachment.message"
                        />
                        <a
                          v-else
                          target="_blank"
                          :href="`${attachmentRoot}${item.path}`"
                          >{{ item.message }}</a
                        >
                      </td>
                      <td>{{ formatDate(item.creationdate) }}</td>
                      <td>{{ formatUserName(item.user_id) }}</td>
                      <td>{{ item.reaction_id ? "Reactie" : "Auteur" }}</td>
                      <td v-if="copyTicket.status !== 'closed'">
                        <div
                          class="table__controls"
                          v-if="+userId === +item.user_id"
                        >
                          <span
                            class="md-24 material-icons success"
                            v-if="activeAttachment?.id === item.id"
                            @click="saveAttachment"
                            >save</span
                          >
                          <span
                            class="md-24 material-icons"
                            v-else
                            @click="activeAttachment = { ...item }"
                            >edit</span
                          >
                          <span
                            class="md-24 material-icons error"
                            @click="deleteAttachment(item.id)"
                            >delete</span
                          >
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <transition name="fade">
                  <Loader v-if="isLoading" />
                </transition>
              </div>
            </div>
            <p v-else>Nog geen bijlages.</p>
            <div
              class="td__content__controls"
              v-if="isTicketAdmin && copyTicket.status !== 'closed'"
            >
              <input
                type="text"
                class="input"
                placeholder="Titel bijlage"
                v-model="newAttachment.message"
              />
              <input
                type="file"
                class="input"
                @change="newAttachmentChanged"
                ref="newAt"
              />
              <div class="td__content__controls">
                <button
                  class="button button--accept"
                  :class="{
                    disabled:
                      !newAttachment.message || !newAttachment.attachment,
                  }"
                  @click="submitAttachement"
                >
                  UPLOAD
                </button>
              </div>
            </div>
            <h3>Reacties</h3>
            <div
              class="td__content__reactions"
              ref="reaction-container"
              v-if="reactions.length"
            >
              <div
                class="td__reaction"
                v-for="item in reactions"
                :key="`reaction--${item.id}`"
              >
                <p class="td__reaction__head">
                  {{ formatDate(item.creationdate) }},
                  {{ formatUserName(item.user_id) }}:
                </p>
                <p class="td__reaction__content">{{ item.message }}</p>
                <p class="td__reaction__attachment" v-if="item.attachment">
                  Bijlage:
                  <a
                    target="_blank"
                    :href="`${attachmentRoot}${item.attachment.path}`"
                    >{{ item.attachment.message }}</a
                  >
                </p>
              </div>
              <transition name="fade">
                <Loader v-if="isLoading" />
              </transition>
            </div>
            <p v-else>Nog geen reacties.</p>
            <div
              class="td__content__newreaction"
              v-if="copyTicket.status !== 'closed'"
            >
              <textarea
                class="textarea"
                v-model="newReaction.message"
              ></textarea>
              <div class="td__content__controls">
                <input
                  type="text"
                  class="input"
                  placeholder="Titel Bijlage"
                  v-model="reactionAttachment.message"
                />
                <input
                  type="file"
                  class="input"
                  @change="reactionAttachmentChanged"
                  ref="reactAt"
                />
                <button
                  class="button button--accept"
                  :class="{ disabled: !newReaction.message }"
                  @click="submitReaction"
                >
                  Reageer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </focus-trap>
    <div class="modal__backdrop" @click="$emit('on-close')"></div>
  </template>
  <teleport to="#modals">
    <TicketLisSearch
      v-if="searchLisActive"
      @on-select="onLisSelectionChanged"
      @on-close="searchLisActive = false"
    />
    <TicketLisDetail
      :lisId="activeMission"
      v-if="activeMission"
      @on-close="activeMission = null"
    />
  </teleport>
</template>

<script>
import TicketLisSearch from "./TicketLisSearch";
import TicketLisDetail from "./TicketLisDetail";
import format from "../../mixins/format";

export default {
  name: "TicketDetail",
  props: {
    ticket: { required: true },
  },
  components: {
    TicketLisSearch,
    TicketLisDetail,
  },
  mixins: [format],
  data() {
    return {
      isLoading: false,
      printing: false,
      copyTicket: { ...this.ticket },
      attachments: [],
      reactions: [],
      newReaction: {
        message: "",
        ticket_id: this.ticket.id,
      },
      newAttachment: {
        message: "",
        ticket_id: this.ticket.id,
        reaction_id: 0,
        attachment: "",
      },
      reactionAttachment: {
        message: "",
        ticket_id: this.ticket.id,
        reaction_id: 0,
        attachment: "",
      },
      activeAttachment: null,
      subStatus: false,
      searchLisActive: false,
      activeMission: null,
    };
  },
  async created() {
    this.loadSubStatus();
    await this.loadContent();
    const container = this.$refs["reaction-container"];
    if (!container) {
      return;
    }
    container.scrollTop = container.scrollHeight;
    this.$nextTick(() => {
      this.$refs.edit_ticket_title.focus();
    });
  },

  computed: {
    userId() {
      return this.$store.getters["userId"];
    },
    isModerator() {
      const userData = this.$store.getters["user/userData"];
      return (
        userData &&
        +userData.moderator &&
        ((this.ticket.A && userData.A) ||
          (this.ticket.G && userData.G) ||
          (this.ticket.V && userData.V) ||
          (this.ticket.ZZ && userData.ZZ))
      );
    },
    isTicketAdmin() {
      return this.userId == this.ticket?.user_id;
    },
    adminReactions() {
      return this.reactions.map((r) =>
        r.user?.id == this.$store.getters["userId"] ? r.id : null
      );
    },
    attachmentRoot() {
      return `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/media/tickets/${this.ticket.id}/`;
    },
  },

  methods: {
    async loadContent() {
      const id = this.ticket.id;
      this.isLoading = true;
      try {
        this.attachments = await this.$store.dispatch("ticket/getAttachments", {
          ticket_id: id,
        });
        await this.fetchReactions(id);
      } catch (e) {
        alert(e);
      }
      this.isLoading = false;
    },

    async loadReactions() {
      const id = this.ticket.id;
      this.isLoading = true;
      try {
        await this.fetchReactions(id);
      } catch (e) {
        alert(e);
      }
      this.isLoading = false;
    },

    async loadSubStatus() {
      try {
        this.subStatus = await this.$store.dispatch("ticket/subscribeStatus", {
          ticket_id: this.ticket.id,
        });
      } catch (e) {
        alert(e);
      }
    },
    async toggleSubscribe() {
      try {
        const on = this.subStatus ? 0 : 1;
        await this.$store.dispatch("ticket/subscribeTicket", {
          ticket_id: this.ticket.id,
          on,
        });
        this.subStatus = !this.subStatus;
      } catch (e) {
        alert(e);
      }
    },

    async fetchReactions(id) {
      const reactions = await this.$store.dispatch("ticket/getReactions", {
        id,
      });
      reactions.forEach((r) => {
        r.attachment = this.attachments.find((a) => a.reaction_id == r.id);
      });
      this.reactions = reactions;
    },

    async loadAttachments() {
      const id = this.ticket.id;
      this.isLoading = true;
      try {
        this.attachments = await this.$store.dispatch("ticket/getAttachments", {
          ticket_id: id,
        });
      } catch (e) {
        alert(e);
      }
      this.isLoading = false;
    },

    async submitTicket() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("ticket/editTicket", { ...this.copyTicket });
        this.$emit("on-save");
        alert("Wijzigingen opgeslagen.");
      } catch (e) {
        alert(e);
      }
      this.isLoading = false;
    },
    async updateTicket(status) {
      this.copyTicket.status = status;
      try {
        await this.submitTicket();
      } catch (e) {
        alert(e);
      }
    },

    async submitReaction() {
      this.isLoading = true;
      const rAt = { ...this.reactionAttachment };
      try {
        const newItem = await this.$store.dispatch("ticket/addReaction", {
          ...this.newReaction,
        });
        this.newReaction.message = "";
        if (rAt && rAt.message && rAt.attachment) {
          rAt.reaction_id = newItem.id;
          await this.$store.dispatch("ticket/addAttachment", rAt);
          this.reactionAttachment.message = "";
          this.$refs.reactAt.value = "";
          this.reactionAttachment.attachment = "";
          await this.loadAttachments();
        }
        await this.loadReactions();
        const container = this.$refs["reaction-container"];
        container.scrollTop = container.scrollHeight;
      } catch (e) {
        alert(e);
      }
      this.isLoading = false;
    },
    async submitAttachement() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("ticket/addAttachment", {
          ...this.newAttachment,
        });
        this.newAttachment.message = "";
        this.$refs.newAt.value = "";
        this.newAttachment.attachment = "";
        await this.loadAttachments();
      } catch (e) {
        alert(e);
      }
      this.isLoading = false;
    },
    newAttachmentChanged(input) {
      if (input.target.files && input.target.files[0]) {
        this.newAttachment.attachment = input.target.files[0];
      }
    },
    reactionAttachmentChanged(input) {
      if (input.target.files && input.target.files[0]) {
        this.reactionAttachment.attachment = input.target.files[0];
      }
    },
    async saveAttachment() {
      const aAt = { ...this.activeAttachment };
      if (!aAt.message) {
        return;
      }
      const payload = {
        id: aAt.id,
        ticket_id: aAt.ticket_id,
        reaction_id: aAt.reaction_id,
        message: aAt.message,
        path: "",
      };
      this.isLoading = true;
      try {
        await this.$store.dispatch("ticket/editAttachment", payload);
        this.activeAttachment = null;
        await this.loadAttachments();
        await this.loadReactions();
      } catch (e) {
        alert(e);
      }
      this.isLoading = false;
    },
    async deleteAttachment(id) {
      this.isLoading = true;
      try {
        await this.$store.dispatch("ticket/delAttachment", { id });
        await this.loadAttachments();
      } catch (e) {
        alert(e);
      }
      this.isLoading = false;
    },

    onLisSelectionChanged(value) {
      if (this.copyTicket) {
        this.copyTicket.lisopdracht_id = value?.LisOpdrachtId;
      }
      this.searchLisActive = false;
    },

    async printTicket() {
      const id = this.ticket.id;
      this.printing = true;
      try {
        const data = await this.$store.dispatch("ticket/printTickets", {
          id,
        });
        if (data.pdf) {
          window.open(`${data.pdf}?${Date.now()}`);
        }
      } catch (e) {
        alert(e);
      }
      this.printing = false;
    },
  },
};
</script>
