import apihelper from "@/helpers/apihelper";

export default {
    async getUserData(context) {
        const token = context.rootGetters.token;
        const id = context.rootGetters.userId;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/users/${id}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch user data.'});
        context.commit('setUserData', {
            userData: responseData.data
        });
    },

    clearUserData(context) {
        context.commit('setUserData', {
            userData: null
        });
    },

    async getUsers(context) {
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/users/listusers`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch users.'});
        context.commit('setUsers', responseData.data);
    },

    async addUser(context, payload) {
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/users/adduser`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to add user.'
        });
    },
    async editUser(context, payload) {
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/users/edituser`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to edit user.'
        });
    },
    async deleteUser(context, payload) {
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/users/deluser`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to delete user.'
        });
    },
    async sendInitPassword(context, {id}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/users/initpassword/${id}`;
        await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to send init password link.'});
    },
    async checkResetCode(context, {username,code}){
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/users/passwordresetvalid?username=${username}&code=${code}`;
        await apihelper.fetchGetRequest({url, token:null, errorMsg: 'Code validation failed.'});
    },
    async resetPassword(context, payload){
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/users/changepassword`;
        await apihelper.fetchPostRequest({
            url,
            token:null,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to reset password.'
        });
    }
};